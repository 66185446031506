@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Oswald", sans-serif;
}

main {
  max-width: 100vw;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: #222;
  color: #fff;
  transition: all 0.3s ease;
  z-index: 1000;
  box-sizing: border-box;
  height: 10vh;
}

header.collapsed {
  padding: 0.5rem 2rem;
  height: 7vh;
  background: rgba(51, 51, 51, 0.9);
}

.logo img {
  width: 166px;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav li {
  margin-left: 2rem;
}

nav a {
  color: #88d3f5;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #fff;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 18px;
  cursor: pointer;
}

.hamburger span {
  height: 3px;
  width: 100%;
  background: #fff;
  transition: all 0.3s ease;
}

.hamburger.open span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

@media (max-width: 768px) {
  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    left: 0;
    background: #222;
    width: 100%;
    padding: 1rem;
    transition: all 0.3s ease;
    box-sizing: border-box;
  }

  nav.open ul {
    display: flex;
    justify-content: center;
    height: calc(100vh - 56px);
  }

  nav li {
    margin: 1rem 0;
    text-align: center;
    font-size: 1.5rem;
  }

  .hamburger {
    display: flex;
  }
}

main {
  flex: 1;
  background-color: #f9f9f9;
  margin-top: 10vh;
}

footer {
  text-align: center;
  padding: 2rem 0;
  background: #222;
  color: #fff;
}

footer ul {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 1rem 0 0 0;
  padding: 0;
}

footer li {
  margin: 0 1rem;
}

footer a {
  color: #88d3f5;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: #fff;
}

.contactInfo p {
  margin: 0.5rem 0;
}

.medaLogo2 {
  width: 60vw;
}
