@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

.contactWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", system-ui;
}

.hero-section {
  padding: 50px;
  text-align: center;
  background: #f4f4f4;
  font-family: "Oswald", system-ui;
  color: #064f86;
}

.contact-form {
  width: 90vw;
  display: flex;
  justify-content: center;
  padding-bottom: 5vh;
  font-family: "Oswald", system-ui;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 90vw;
  padding: 10px;
  margin: 5px 0;
  font-family: "Oswald", system-ui;
}

.submit-button {
  padding: 10px 20px;
  background: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.business-info {
  text-align: center;
  padding: 50px;
  background: #f4f4f4;
}
