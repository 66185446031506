.testimonial-section {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  font-family: "Oswald", system-ui;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.testimonial-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #88d3f5;
}

.testimonial-card {
  padding: 1.5rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 auto; /* Center the card */
  max-width: 90%; /* Ensure responsiveness */
  box-sizing: border-box;
  color: #074f80;
}

.testimonial-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: #074f80;
  margin: 0.5rem 0;
}

.testimonial-state,
.testimonial-products {
  font-size: 1rem;
  color: #074f80;
  margin: 0.5rem 0;
}

.testimonial-text {
  font-size: 1rem;
  font-style: italic;
  color: #074f80;
  margin-top: 1rem;
}

/* Slick dots styling */
.slick-dots {
  text-align: center; /* Center the dots */
}

.slick-dots li button:before {
  font-size: 0.8rem;
  color: #3498db;
}
.slick-dots li.slick-active button:before {
  color: #2980b9;
}

/* Responsive design */
@media (max-width: 768px) {
  .testimonial-section {
    background-color: rgba(51, 51, 51, 0.9);
    padding-top: 0;
    margin-top: 2vh;
    height: 40vh;
    width: 96vw;
  }

  .testimonial-section h2 {
    font-size: 1.5rem;
  }

  .testimonial-card {
    padding: 1rem;
  }

  .testimonial-text {
    width: 80%;
    justify-self: center;
  }
}
