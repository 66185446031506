@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

hr {
  margin-top: none;
  width: 70%;
}

.carriersContent {
}

.carrierTop {
  padding-top: 11rem;
  padding-bottom: 7rem;
  color: white;
  padding-left: 11rem;
}

.carrierTopQuote h3 {
  font-family: "Oswald", sans-serif;

  font-weight: 600;
  font-style: normal;
  font-size: 2rem;
}

.carrierTopQuote h2 {
  font-family: "Oswald", sans-serif;

  font-weight: 200;
  font-style: normal;
  font-size: 2rem;
}

.carrierMiddle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.carrierLogoContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-content: center;
  align-items: center;
}

.carrierInfoText h1 {
  font-family: "Oswald", sans-serif;

  font-weight: 400;
  font-style: normal;
  font-size: 3rem;
  text-transform: uppercase;
}

#carrierLogos {
  max-width: 8rem;
  padding: 2rem;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 1 / 5 / 2 / 6;
}
.div6 {
  grid-area: 2 / 1 / 3 / 2;
}
.div7 {
  grid-area: 2 / 2 / 3 / 3;
}
.div8 {
  grid-area: 2 / 3 / 3 / 4;
}
.div9 {
  grid-area: 2 / 4 / 3 / 5;
}
.div10 {
  grid-area: 2 / 5 / 3 / 6;
}
.div11 {
  grid-area: 3 / 1 / 4 / 2;
}
.div12 {
  grid-area: 3 / 2 / 4 / 3;
}
.div13 {
  grid-area: 3 / 3 / 4 / 4;
}
.div14 {
  grid-area: 3 / 4 / 4 / 5;
}
.div15 {
  grid-area: 3 / 5 / 4 / 6;
}
.div16 {
  grid-area: 4 / 1 / 5 / 2;
}
.div17 {
  grid-area: 4 / 2 / 5 / 3;
}
.div18 {
  grid-area: 4 / 3 / 5 / 4;
}
.div19 {
  grid-area: 4 / 4 / 5 / 5;
}
.div20 {
  grid-area: 4 / 5 / 5 / 6;
}
.div21 {
  grid-area: 5 / 2 / 6 / 3;
}
.div22 {
  grid-area: 5 / 4 / 6 / 5;
}

.carrierInfo {
  background-color: #074f80;
  color: #ffffff;
  text-align: center;
  padding: 4rem;
  padding-left: 4rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carrierInfoText {
  width: 70%;
  font-family: "Oswald", sans-serif;
  font-size: large;
}

@media (max-width: 1080px) {
  .carrierTop {
    object-fit: cover;
    margin-top: 5vh;
    padding-top: 7rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  }

  .carrierInfo {
    width: 100%;
    padding: 0;
    padding-left: 0;
    padding-bottom: 2rem;
  }

  .carrierInfoText {
    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carrierInfoText h1 {
    font-size: 44px;
    width: 80%;
    text-align: center;
  }
  hr {
    margin-bottom: 2rem;
  }

  .carrierLogoWrapper {
    display: flex;
    justify-content: flex-end;
  }

  .carrierLogoContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(8, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  #carrierLogos {
    max-width: 5rem;
    padding: 1rem;
  }

  .div1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 2 / 3;
  }
  .div3 {
    grid-area: 1 / 3 / 2 / 4;
  }
  .div4 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .div5 {
    grid-area: 2 / 2 / 3 / 3;
  }
  .div6 {
    grid-area: 2 / 3 / 3 / 4;
  }
  .div7 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .div8 {
    grid-area: 3 / 2 / 4 / 3;
  }
  .div9 {
    grid-area: 3 / 3 / 4 / 4;
  }
  .div10 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .div11 {
    grid-area: 4 / 2 / 5 / 3;
  }
  .div12 {
    grid-area: 4 / 3 / 5 / 4;
  }
  .div13 {
    grid-area: 5 / 1 / 6 / 2;
  }
  .div14 {
    grid-area: 5 / 2 / 6 / 3;
  }
  .div15 {
    grid-area: 5 / 3 / 6 / 4;
  }
  .div16 {
    grid-area: 6 / 1 / 7 / 2;
  }
  .div17 {
    grid-area: 6 / 2 / 7 / 3;
  }
  .div18 {
    grid-area: 6 / 3 / 7 / 4;
  }
  .div19 {
    grid-area: 7 / 1 / 8 / 2;
  }
  .div20 {
    grid-area: 7 / 2 / 8 / 3;
  }
  .div21 {
    grid-area: 7 / 3 / 8 / 4;
  }
  .div22 {
    grid-area: 8 / 1 / 9 / 2;
  }
  .div23 {
    grid-area: 8 / 3 / 9 / 4;
  }
}
