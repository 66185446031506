.aboutContainer {
  max-width: 100vw;
  overflow: hidden;
}

.aboutSplash {
  background-image: url(../../Images/background/aboutBG.png);
  width: 100vw;
  height: 30vh;

  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  background-size: cover;
  background-repeat: no-repeat;
}

.aboutSplash h1 {
  color: white;
  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 2.2rem;
  text-align: start;
  margin-bottom: 0;
  text-align: center;
  text-wrap: balance;
  margin-top: 0;
  background-color: rgba(6, 79, 134, 0.7);
  animation-name: slide-in;
  animation-duration: 0.5s;
}

.aboutSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  text-align: center;
  text-wrap: balance;
  background-image: url(../../Images/background/aboutTopBG.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto;
  background-color: rgba(360, 360, 360, 0.5);
}

.aboutSection p {
  color: #074f80;
  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 1rem;
}

.aboutSection h3 {
  color: #074f80;
  font-family: "Oswald", system-ui;
}

.aboutSecTop {
  width: 100vw;
  background-color: rgba(360, 360, 3600, 0.1);
}

.aboutList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(51, 51, 51, 0.9);
  width: 96vw;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.aboutListBrake {
  width: 90%;
  background-color: #88d3f5;
}

#aboutListTitle {
  color: #88d3f5;
  margin-top: 0px;
  margin-bottom: 0px;
}

#aboutListItems {
  font-size: 1.4rem;
  margin: 1rem 0;
  color: #88d3f5;
  width: 80%;
}

.testimonialSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
