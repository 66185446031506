.quoteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  height: auto;
  max-width: 100%;
  animation: fadeIn 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h2 {
  text-align: center;
  text-wrap: balance;
}

.quoteForm {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

label {
  display: flex;
  flex-direction: column;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.5s;
}

.error {
  color: red;
  font-size: 0.8em;
}

.alert {
  margin-top: 20px;
  color: green;
  font-size: 1em;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
