.options-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.option {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #064f86;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.displayedContent {
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
}

.expandedContent {
  display: flex;
  justify-content: center;
}

.option:hover {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: solid #88d3f5 1px;
  transition: 0.1s;
}

.option img {
  margin-right: 10px;
  height: 3rem;
  padding: 0.5rem;
}

.option h1 {
  font-size: 1.5rem;
  margin: 0;
}

.option p {
  font-size: 1rem;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  width: 90%;
  text-align: center;
}
