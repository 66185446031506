.servicesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.servicesTop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  background-image: url("../../Images/background/servicesBG.png");
  text-align: center;
  height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.servicePromises {
  display: flex;
  flex-direction: column;
  width: 80%;
}

#lBPromises {
  color: #88d3f5;
  margin-bottom: 0;
}

ul {
  display: flex;

  justify-content: space-between;
  padding: 0;
  list-style-type: none;
  color: #064f86;
  font-family: "Oswald", system-ui;
  font-weight: 300;
  font-size: 2rem;
  text-align: center;
}

li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.servicesTop h2 {
  color: white;
  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 2.2rem;
  text-align: start;
  margin-bottom: 0;
  text-align: center;
  text-wrap: balance;
  margin-top: 0;
  background-color: rgba(6, 79, 134, 0.7);
  width: 100%;
  animation-name: slide-in;
  animation-duration: 0.5s;
}

.servicesBreak {
  color: #064f86;
  width: 80%;
}

.servicesSection {
}

.servicesTopBottom {
  background-image: url("../../Images/background/aboutTopBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

.carriersCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(360, 360, 360, 0.5);
}

.carriersCont a {
  width: 96vw;
  color: #064f86;
  text-wrap: balance;
  text-align: center;
}

.carriersCont h1 {
  font-family: "Oswald", system-ui;
  font-weight: 300;
  font-size: 4vh;
  background-color: #064f86;
  width: 100%;
  text-align: center;
  color: white;
  padding-bottom: 1vh;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 0;
}

.carrierContPic {
  width: 96vw;
}

.servicesPromise {
  width: 100%;
  color: #064f86;

  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 0;
  text-wrap: balance;
  text-align: center;

  padding-bottom: 2rem;
}

.servicesMiddle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#productTypesH1 {
  color: white;
  font-family: "Oswald", system-ui;
  font-weight: 300;
  font-size: 4vh;
  background-color: #064f86;
  width: 100vw;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1vh;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.servicesBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  background-image: url("../../Images/background/aboutTopBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0, 30%;
}

.servicesBottom h1 {
  font-family: "Oswald", system-ui;
  font-weight: 300;
  font-size: 4vh;
  background-color: #064f86;
  width: 100%;
  text-align: center;
  color: white;
  padding-bottom: 1vh;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-top: 0;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
