.healthWrapper {
  text-align: center;
  font-family: "Oswald", system-ui;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
  box-sizing: border-box;
}

.carousel-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  margin-bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.health-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 40vh;
  max-width: 400px;
  margin: 0 auto; /* Center the card */

  text-align: center;
  text-wrap: balance;
  border: solid #064f86 2px;
  color: #064f86;
  padding: 10px;
  background-color: white;
  transition: transform 0.3s ease;
  box-sizing: border-box; /* Ensure padding and border are included in dimensions */
}

.health-card:hover {
  transform: scale(1.05);
}

.health-card-image {
  width: 20%; /* Set image width to 20% of the card */
  height: auto;
  border-radius: 5px;
}

button {
  margin-top: 10px;
  padding: 10px;
  background-color: #064f86;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Allow scrolling if content is too large */
}

.modal-content {
  background-color: white;
  border-radius: 10px;
  width: 90vw; /* Set modal width to 90% of viewport width */
  height: 90vh; /* Set modal height to 90% of viewport height */
  max-height: 90vh; /* Ensure it doesn't exceed viewport height */
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

.modal-content h2 {
  color: #064f86; /* Set the color for the h2 elements in the modal */
  margin-top: 10px;
  font-size: 1.2em;
}

.close-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
  margin: -10px -10px 10px 0; /* Adjust positioning of close button */
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .carousel-card {
    width: 100%; /* Ensure carousel-card is full width */
    padding: 0; /* Remove horizontal padding */
  }

  .health-card {
    width: 100%; /* Full width on mobile */
    margin: 0 auto; /* Center card within carousel */
  }
}
