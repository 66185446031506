@import url("https://fonts.googleapis.com/css2?family=Faster+One&family=Oswald:wght@200..700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

.homeWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.homeSplashSection {
  width: 100%;
}

.homeSplashContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#splashService {
  display: flex;

  align-items: center;
  color: white;
}
.splashServices {
  display: flex;
  justify-content: space-around;
}

.homeSplashContent {
  width: 100%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.topTop {
  height: 70%;

  background-size: cover;
  background-position: center;
  background-image: url(../../Images/background/2.png);
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.homeSplashContent h1 {
  color: white;
  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 2.2rem;

  text-align: start;
  margin-bottom: 0;
  text-align: center;
  text-wrap: balance;
  margin-top: 33%;
  background-color: rgba(6, 79, 134, 0.5);
}

.conButtContainer {
  display: flex;
  justify-content: center;
  background-color: white;
}

.quoteButton {
  background-color: rgba(7, 79, 128, 0.99);
  margin-top: -88px;
  margin-bottom: 1rem;
  width: 12rem;
  height: 4rem;
  font-family: "Oswald", sans-serif;
  font-weight: 800;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.5rem;
  color: white;
  border-radius: 0px;
  transition: 0.3s;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  animation: myAnim 1s ease 0s 1 normal forwards;
}

.quoteButton:hover {
  color: #074f80;
  background-color: rgba(136, 207, 242, 0.7);
  border: solid rgba(7, 79, 128, 0.5) 3px;
  transition: 0.3s;
  text-shadow: -1px -1px 10px rgba(360, 360, 360, 0.3);
}

.homeSplashContentBottom {
  height: 30%;
  width: 100%;
  display: flex;
  background-image: url(../../Images/background/splashBGBot.png);

  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
}

.homeSplashContent h2 {
  color: rgba(6, 79, 134, 0.99);

  font-family: "Oswald", system-ui;
  font-weight: 250;
  font-size: 1.8rem;
  padding: 0;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}

.homeSplashContent h3 {
  color: rgba(6, 79, 134, 1);
  font-family: "Oswald", system-ui;
  font-weight: 300;
  margin-top: 2vh;
  text-decoration: underline;
}

.lowCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splashLogo {
  width: 50%;
  animation: myAnim2 1s ease 1s normal forwards;
}

.aboutWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../../Images/background/aboutTopBG.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0% 50%;
}

#whiteH1 {
  margin-top: 0;
  margin-bottom: 0;
  color: white;
  background-color: #074f80;
  font-family: "Oswald", system-ui;
  font-weight: 100;
  font-size: 6vh;
  width: 100%;
  text-shadow: 2px 4px 2px rgba(6, 79, 134, 0.8);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(360, 360, 360, 0.5);
}

.whatWeDoPic {
  height: 25vh;
  width: 100vw;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

  transition: opacity 1s ease, transform 1s ease;
}

.whatWeDoPic.visible {
  opacity: 1;
  transform: translateX(0);
}

.aboutContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #074f80;
  font-family: "Oswald", system-ui;
  font-weight: 400;
  font-size: 1rem;
  width: 80%;
  text-align: left;
}

.seperation {
  width: 90%;
}

.valuesWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(360, 360, 360, 0.5);
}

.valuesWrapper h1 {
  color: rgba(6, 79, 134, 1);
  font-family: "Oswald", system-ui;
  font-weight: 100;
  font-size: 6vh;
  background-color: #88d3f5;
  width: 100%;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

.valuesContainer h2 {
  color: rgba(6, 79, 134, 1);
  font-family: "Oswald", system-ui;
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 0;
}

.valuesContainer p {
  font-family: "Oswald", system-ui;
  text-align: center;
  font-size: 1rem;
  font-weight: 400;
  color: #074f80;
  background-color: #88d3f5;
  margin-bottom: 0;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}

#valueContents {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2vh;
  background: radial-gradient(
        circle at 100% 100%,
        #ffffff 0,
        #ffffff 3px,
        transparent 3px
      )
      0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px)
      100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px)
      0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100%
      100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 10px)
      calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 16px)
      calc(100% - 10px) no-repeat,
    linear-gradient(0deg, #88d3f5 80%, #064f86 100%);

  padding-top: 1vh;

  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.valuePics {
  height: 15vh;

  margin-bottom: 0;
}

.testimonialsWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 5vh;
}

.quoteBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Oswald", system-ui;

  border-bottom: solid #88d3f5 4px;
}

.quoteBottom h1 {
  width: 90%;
  text-align: center;
  font-family: "Oswald", system-ui;
  font-weight: 200;
  font-size: 2rem;
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
#animate-area {
  width: 200px;
  height: 200px;
  background-image: url(https://picsum.photos/400/200);
  background-position: 0px 0px;
  background-repeat: repeat-x;
  animation: animatedBackground 10s linear infinite alternate;
}

@keyframes myAnim {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes myAnim2 {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slideIn {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.slideInLeft {
  opacity: 1;
  transform: translateX(0);
}
