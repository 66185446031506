.valuesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.value {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 5vh;
  padding-bottom: 0;
}

.displayedValue {
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0;
}

.valuesContainer p {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.displayedValue h2 {
  margin-top: 0;
}

.expandedValue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0;
  width: 100%;
  transition: ease-in 4s;
  border-bottom: solid #074f80 2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

#valueImage {
  height: 5rem;
}
